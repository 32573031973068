<template>
  <CContainer>
    <GridSelector
      v-if="!loading"
      show-add-button
      @open-create="openModal"
      @open-update="openModal"
    />
    <div v-else class="h-[300px] flex items-center justify-center">
      <CLoadingSpinner class="w-6" />
    </div>
    <Portal to="overlays">
      <CreateUpdateModal v-model="showCreateUpdateModal" />
    </Portal>
  </CContainer>
</template>

<script>
import { mapActions } from 'vuex';
import merchantWasteFormStore from '@/store/dynamicModules/merchantWasteForm';
import GridSelector from '@/ui/components/Merchant/MerchantWastes/GridSelector.vue';
import CreateUpdateModal from '@/ui/components/Merchant/MerchantWastes/CreateUpdateModal.vue';
import { INIT_MERCHANT_WASTES } from '@/store/actionTypes';

export default {
  components: {
    GridSelector,
    CreateUpdateModal,
  },

  data: () => ({
    showCreateUpdateModal: false,
  }),

  computed: {
    loading() {
      return this.$store.state.merchantWastes.loading;
    },
  },

  created() {
    this.initMerchantWastes();
    if (!this.$store.hasModule('merchantWasteForm')) {
      this.$store.registerModule('merchantWasteForm', merchantWasteFormStore);
    }
  },

  beforeDestroy() {
    if (!this.$store.hasModule('merchantWasteForm')) {
      this.$store.unregisterModule('merchantWasteForm', merchantWasteFormStore);
    }
  },

  methods: {
    ...mapActions([INIT_MERCHANT_WASTES]),
    openModal(data = null) {
      if (data === null) {
        this.$store.commit('merchantWasteForm/resetState');
        this.showCreateUpdateModal = true;
      } else {
        this.$store.commit('merchantWasteForm/setUpdateData', data);
        this.showCreateUpdateModal = true;
      }
    },
  },
};
</script>
